import {
  addDays,
  addHours,
  addMilliseconds,
  addMinutes,
  addMonths,
  addSeconds,
  addWeeks,
  addYears,
  closestTo,
  compareAsc,
  differenceInCalendarDays,
  differenceInCalendarYears,
  differenceInDays,
  differenceInHours,
  differenceInMilliseconds,
  differenceInMinutes,
  differenceInMonths,
  differenceInSeconds,
  differenceInWeeks,
  differenceInYears,
  eachDayOfInterval,
  endOfDay,
  endOfISOWeek,
  format,
  formatDistanceStrict,
  formatDistanceToNow,
  getISODay,
  getISOWeek,
  getISOWeekYear,
  getMinutes,
  getMonth,
  getYear,
  isAfter,
  isBefore,
  isEqual,
  isFirstDayOfMonth,
  isFuture,
  isMonday,
  isPast,
  isSameDay,
  isSameMonth,
  isThisMinute,
  isThisMonth,
  isThisWeek,
  isToday,
  isTomorrow,
  isValid,
  isWeekend,
  isWithinInterval,
  isYesterday,
  lastDayOfISOWeek,
  lastDayOfMonth,
  min,
  setHours,
  setMilliseconds,
  setMinutes,
  setMonth,
  setSeconds,
  setYear,
  startOfDay,
  startOfISOWeek,
  startOfMinute,
  startOfMonth,
  subDays,
  subHours,
  subMinutes,
  subMonths,
  subWeeks,
  subYears,
} from 'date-fns';

const useDateTimeUtils = () => {
  return {
    addDays,
    addHours,
    addMilliseconds,
    addMinutes,
    addMonths,
    addSeconds,
    addWeeks,
    addYears,
    closestTo,
    compareAsc,
    differenceInCalendarDays,
    differenceInCalendarYears,
    differenceInDays,
    differenceInHours,
    differenceInMilliseconds,
    differenceInMinutes,
    differenceInMonths,
    differenceInSeconds,
    differenceInWeeks,
    differenceInYears,
    eachDayOfInterval,
    endOfDay,
    endOfISOWeek,
    format,
    formatDistanceStrict,
    formatDistanceToNow,
    getISODay,
    getISOWeek,
    getISOWeekYear,
    getMinutes,
    getMonth,
    getYear,
    isAfter,
    isBefore,
    isEqual,
    isFirstDayOfMonth,
    isFuture,
    isMonday,
    isPast,
    isSameDay,
    isSameMonth,
    isThisMinute,
    isThisMonth,
    isThisWeek,
    isToday,
    isTomorrow,
    isValid,
    isWeekend,
    isWithinInterval,
    isYesterday,
    lastDayOfISOWeek,
    lastDayOfMonth,
    min,
    setHours,
    setMilliseconds,
    setMinutes,
    setMonth,
    setSeconds,
    setYear,
    startOfDay,
    startOfISOWeek,
    startOfMinute,
    startOfMonth,
    subDays,
    subHours,
    subMinutes,
    subMonths,
    subWeeks,
    subYears,
  };
};

export default useDateTimeUtils;
