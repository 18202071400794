import styled from '@emotion/styled';
import { FormControlLabel } from '@material-ui/core';

import { Button, IconButton as IconButtonComponent } from 'components/buttons';
import DialogComponent from 'components/dialogs/DialogBuilder';
import Popover from 'components/dialogs/PopoverBuilder';
import ListItemComponent from 'components/ellipsisDropdown/listItem-view';
import Text from 'components/text/Text';
import { HStack, VStack } from 'layouts/box/Box';
import transientOptions from 'theme/helpers';

import { ToolbarIcons } from '../command-constants';

export const StyledFormControl = styled(FormControlLabel)`
  margin-left: 0;
  margin-right: 0;
  .MuiCheckbox-root {
    width: 22px;
    height: 22px;
    margin-right: 6px;
  }
  .MuiFormControlLabel-label {
    font-size: 13px;
  }
`;

export const Wrapper = styled(VStack, transientOptions)<{ $dark?: boolean }>`
  border-top: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
  border-bottom: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
  background: ${({ $dark, theme }) => ($dark ? theme.palette.dina.surfacePaper : 'inherit')};
  overflow: unset;
`;

export const FirstRow = styled(HStack, transientOptions)<{ $height?: string }>`
  width: 100%;
  min-height: ${({ $height }) => $height ?? '32px'};
  justify-content: start;
`;

export const SecondRow = styled(HStack)`
  width: 100%;
  gap: 8px;
  justify-content: start;
  align-items: start;

  .member-wrapper {
    height: 24px;
  }
  .avatar {
    width: 20px;
    height: 20px;
    min-width: 20px;
  }
  .member-details {
    font-size: 12px;
  }
`;

const sizeMap: Record<string, number> = {
  small: 10,
  medium: 12,
  max: 12,
};

export const StyledText = styled(Text, transientOptions)<{ $size?: string }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  flex-shrink: 0;
  &:hover {
    color: ${({ theme }) => theme.palette.dina.highEmphasis};
  }
  &:hover svg path {
    fill-opacity: 1;
  }
  font-size: ${({ $size }) => ($size ? sizeMap[$size] : 12)}px;
`;

const IconStyles = `
  height: 18px;
  width: 18px;
  cursor: pointer;
  &:hover path {
    fill-opacity: 1;
  }
`;

export const SidePanelActive = styled(ToolbarIcons.SidePanelActive)`
  ${IconStyles}
`;

export const SidePanelInActive = styled(ToolbarIcons.SidePanelInActive)`
  ${IconStyles}
`;

export const MetadataInactive = styled(ToolbarIcons.MetadataInactive)`
  ${IconStyles}
`;

export const MetadataActive = styled(ToolbarIcons.MetadataActive)`
  ${IconStyles}
`;

export const ClearAll = styled(ToolbarIcons.ClearAll)`
  ${IconStyles}
  flex-shrink: 0
`;

export const StyledCaret = styled(ToolbarIcons.Caret)`
  margin-left: 4px;
  margin-top: 2px;
`;

export const StyledClear = styled(ToolbarIcons.Clear)`
  margin-left: 4px;
  margin-top: 2px;
  width: 18px;
  height: 18px;
`;

export const ColumnHeader = styled(Text)`
  margin-bottom: 8px !important;
`;

export const ToolbarItemColumn = styled(VStack)`
  padding: 4px;
  align-items: start;
  &.disabled * {
    opacity: 0.6;
    cursor: default !important;
  }
`;

export const ToolbarItemLabel = styled(Text)`
  margin-left: 8px !important;
  cursor: pointer;
`;

export const StyledRightSide = styled(HStack)`
  .MuiFormControlLabel-root {
    margin-right: 0;
  }
`;

export const ToolbarItem = styled(HStack)`
  padding: 8px;
  gap: 12px;
  justify-content: start !important;
  align-items: start;
`;

export const MoreToolbarItem = styled(VStack)`
  padding-bottom: 8px;
  a {
    display: flex;
    gap: 8px;
    align-items: center;
    text-decoration: none;
  }
`;

export const ListItem = styled(ListItemComponent)`
  width: 396px;
  height: 32px;
  &:hover {
    span {
      color: ${({ theme }) => theme.palette.dina.highEmphasis};
    }
    svg path {
      fill-opacity: 1;
    }
  }
`;

export const Info = styled(HStack)`
  ${({ theme }) => theme.typography.dina.caption};
  text-align: right;
  &:hover {
    color: ${({ theme }) => theme.palette.dina.highEmphasis};
  }
`;

export const ExportOutlineButton = styled(Button)`
  justify-content: flex-start;
  padding: 0 16px;
  :not(:hover) {
    background-color: transparent;
  }
`;

export const ExportConfirmWrapper = styled(VStack)`
  max-width: 396px;
  background-color: ${({ theme }) => theme.palette.dina.dialogBackdropOverlay};
  border-radius: 8px;
`;

export const ExportConfirmMessage = styled(HStack)`
  gap: 8px;
  padding: 12px 16px 16px 16px;
  border-bottom: ${({ theme }) => `1px solid ${theme.palette.dina.borderResting}`};
`;

export const ExportConfirmActions = styled(HStack)`
  gap: 4px;
  width: 100%;
  padding: 8px;
  justify-content: flex-end;
`;

export const ExportDialogHeader = styled(HStack)`
  justify-content: space-between;
  width: 100%;
  padding: 8px 8px 8px 16px;
  border-bottom: ${({ theme }) => `1px solid ${theme.palette.dina.borderResting}`};
`;

export const ExportWarningMessage = styled(HStack)`
  gap: 8px;
  padding: 8px 8px 12px 8px;
  align-items: flex-start;
  background-color: ${({ theme }) => theme.palette.dina.warningBackground};
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  border-color: ${({ theme }) => theme.palette.dina.warningBorder};
  & > svg {
    width: 40px;
    & > path {
      fill: ${({ theme }) => theme.palette.dina.statusWarning};
      fill-opacity: 1;
    }
  }
`;

export const IconButton = styled(IconButtonComponent)`
  flex-shrink: 0;
`;

export const PopoverContent = styled(Popover.Content)`
  min-width: 100px;
`;

export const PopoverTrigger = styled(Popover.Trigger)`
  all: unset;
`;

export const Dialog = styled(DialogComponent)`
  pointer-events: auto;
  max-width: 396px;
`;
