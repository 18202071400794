import { MdfCategory, useGetMdfCategory } from 'api/mdf/useGetMdfs';
import { Alternative, Mdf } from 'types/graphqlTypes';

import {
  createLabelValidator,
  MdfItemCreationInfoMapper,
  MdfItemInfoMapper,
} from './mdfItemHelper';

export const SCHEMA_GROUP_DISPLAY_TEXTS: Readonly<Record<MdfCategory, [string, string]>> =
  Object.freeze({
    blocks: ['editor blocks schema', 'an editor blocks schema'],
    instances: ['instance schema', 'an instance schema'],
    defaults: ['system schema', 'a system schema'],
    custom: ['custom schema', 'a custom schema'],
    subTypes: ['subtype', 'a subtype'],
  });

/* eslint-disable max-len */
export const SCHEMA_GROUP_INFO_TEXTS: Readonly<Record<MdfCategory, string>> = Object.freeze({
  defaults:
    'System default schemas are schemas that are automatically used for various items in the system',
  custom:
    'A custom schema can be connected to order forms, planning items, and other configurations.',
  instances:
    'Add a new schema for a specific instance platform. If a schema for a platform already exists, it will not appear here.',
  blocks:
    'Configure custom fields on blocks of editor content. Currently only a subset of blocks on CMS editor are supported.',
  subTypes:
    'A subtype is a set of fields, that can be configured that will appear based on a user choice in another schema.',
});
/* eslint-enable max-len */

export function getCategoryTypeText(category: MdfCategory, indefinite: boolean) {
  return SCHEMA_GROUP_DISPLAY_TEXTS[category][indefinite ? 1 : 0];
}

function getChangeInfoItem(mdf: Mdf) {
  return mdf;
}

/**
 * A hook to get a {@link MdfItemCreationInfoMapper} for MDF schemas
 * @returns An info mapper that can be used with `CreateMdfItemDialog`
 */
export function useMdfSchemaInfoMapper(): MdfItemCreationInfoMapper<Mdf, Mdf, MdfCategory> {
  const getMdfCategory = useGetMdfCategory();

  function getItemTypeText(mdf: Mdf, indefinite: boolean): string {
    return SCHEMA_GROUP_DISPLAY_TEXTS[getMdfCategory(mdf)][indefinite ? 1 : 0];
  }

  return {
    getItemTypeText,
    getChangeInfoItem,
    getCategoryTypeText,
    infoTexts: SCHEMA_GROUP_INFO_TEXTS,
  };
}

const SIMPLE_INFO_MAPPER: MdfItemInfoMapper<Mdf, Mdf> = {
  getItemTypeText: () => 'schema',
  getChangeInfoItem: (changeInfo) => changeInfo,
};
Object.freeze(SIMPLE_INFO_MAPPER);

/**
 * Creates a function that can be used to validate the label of an option list/tree
 * @param mdfsSeparated The schemas in the system as retrieved from the back-end
 * @param changedMdfs   The schemas that have been changed but not yet saved
 * @param originalLabel The original label
 * @param group         The group that the schema being renamed belongs to
 * @returns             The validator function that receives a label and returns an error
 *                      message text if not OK or `true` if ok.
 */
export function createSchemaLabelValidator(
  mdfsSeparated: Readonly<Record<MdfCategory, readonly Mdf[]>>,
  changedMdfs: Readonly<Record<string, Mdf>>,
  originalLabel: string,
  group: MdfCategory,
): (label: string) => string | boolean {
  const allMdfs = Object.values(mdfsSeparated).flat();
  return createLabelValidator(
    allMdfs,
    changedMdfs,
    originalLabel,
    SCHEMA_GROUP_DISPLAY_TEXTS[group][0],
    SIMPLE_INFO_MAPPER,
  );
}

export function isModernSubtypeId(subtypeId: string): boolean {
  return subtypeId.indexOf('-') < 0;
}

/**
 * Creates the alternative to be used for a subtype.
 * If the subtype has a legacy ID (uuid), its label is used for both value and label,
 * Otherwise we use the ID as alterative value and the label as the alternative label
 * except if the label and ID are equal where we use an empty label.
 * (This is done to be able to use the fact that the label and the value are different
 * to know that the alternative value represents a subtype ID)
 * @param id    The ID of the subtype
 * @param label The label of the subtype
 * @returns     The alternative
 */
export function createSubtypeAlternative(id: string, label: string): Alternative {
  if (!isModernSubtypeId(id)) return { id: label, value: label, label };
  if (id === label) label = '';
  return { id, value: id, label };
}
