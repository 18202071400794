import { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Tooltip } from '@material-ui/core';

import { useGetMdfs } from 'api/mdf/useGetMdfs';
import { CheckboxWithLabel } from 'components/createNewV3/CreateNew';
import Dialog from 'components/dialogs/DialogBuilder';
import { useChangedMdfs } from 'screens/main/components/header/navbar/settings/atomsTs';
import {
  createSubtypeAlternative,
  isModernSubtypeId,
} from 'screens/main/components/header/navbar/settings/components/mdf/schemaHelpers';
import { Mutable } from 'types';
import { Alternative, FieldTypeEnum, Mdf, MdfField } from 'types/graphqlTypes';
import { EMPTY_ARRAY } from 'utils/arrayUtils';
import useMemoMap from 'utils/hooks/useMemoMap';
import { localeLabelCompare } from 'utils/objects';

const getSubtypeFields = (mdfs: readonly Mdf[]): Set<string> => {
  const types = new Set<string>();
  for (const mdf of mdfs) {
    for (const subTypeField of mdf.fields.filter((md) => md.type === FieldTypeEnum.subtype)) {
      (subTypeField.alternatives ?? []).forEach((alt) => types.add(alt.id));
    }
  }
  return types;
};

interface SubtypeInfo {
  readonly id: string;
  readonly label: string;
  readonly problem?: string;
}

// EG given subtype A, B, C, D
// if C is configured in subtype B, it should not be available in A and D
// if any "main" mdf configures a subtype, it should not be available in any subtype
// a subtype cannot configure itself in a subtype
const getAvailableSubtypes = (
  availableSubTypes: readonly Mdf[],
  selectedMdf: Mdf,
): readonly SubtypeInfo[] => {
  const configuredSubtypeFieldsInOtherSubtypes: Set<string> = getSubtypeFields(availableSubTypes);

  const result: SubtypeInfo[] = [];
  for (const mdf of availableSubTypes) {
    const item: Mutable<SubtypeInfo> = { id: mdf.id, label: mdf.label };
    if (selectedMdf.isSubtype && configuredSubtypeFieldsInOtherSubtypes.has(mdf.id)) {
      item.problem = 'This subtype is already configured in a different subtype schema';
    }
    result.push(Object.freeze(item));
  }
  result.sort(localeLabelCompare);
  return Object.freeze(result);
};

interface Props {
  open: boolean;
  setOpen: (val: boolean) => void;
  field: MdfField;
  updateAlternatives: (alts: Alternative[]) => void;
  selectedMdf: Mdf;
}

export function ConfigSubtypeDialog({
  open,
  setOpen,
  updateAlternatives,
  field,
  selectedMdf,
}: Readonly<Props>) {
  const { mdfsSeparated } = useGetMdfs();
  const [changedMdfs] = useChangedMdfs();
  const [fieldToConfigure, setFieldToConfigure] = useState({ ...field });
  /** Map from ID of the other sub types to their MDFs */
  const otherSubtypeMdfs = useMemoMap(
    Object.fromEntries(
      mdfsSeparated.subTypes
        .filter((mdf) => mdf.id !== selectedMdf.id)
        .map((mdf) => [mdf.id, changedMdfs[mdf.id] ?? mdf]),
    ),
  );

  useEffect(() => {
    setFieldToConfigure({ ...field });
  }, [field]);

  const toggleSubtype = useCallback(
    (id: string, label: string) => {
      const value = isModernSubtypeId(id) ? id : label;
      setFieldToConfigure((prevValue) => {
        const prevAlternatives = prevValue.alternatives ?? [];
        const wasIncluded = !!prevAlternatives.find((alt) => alt.value === value);
        const rawAlternatives = wasIncluded
          ? prevAlternatives.filter((a) => a.value !== value)
          : [...prevAlternatives, createSubtypeAlternative(id, label)];
        // Make sure that all labels are up to date for subtypes with modern IDs
        const alternatives = rawAlternatives.map((alt) => {
          if (alt.value === alt.label) return alt; // Legacy
          const updatedMdf = otherSubtypeMdfs[alt.value];
          if (!updatedMdf || updatedMdf.label === (alt.label || alt.value)) return alt;
          return createSubtypeAlternative(alt.value, updatedMdf.label);
        });
        return { ...prevValue, alternatives };
      });
    },
    [otherSubtypeMdfs, setFieldToConfigure],
  );

  const availableSubtypes = useMemo(() => {
    return getAvailableSubtypes(Object.values(otherSubtypeMdfs), selectedMdf);
  }, [otherSubtypeMdfs, selectedMdf]);

  const selectedSubtypes = useMemo(() => {
    const alternatives = fieldToConfigure.alternatives;
    if (!alternatives?.length) return EMPTY_ARRAY as readonly string[];
    return availableSubtypes
      .filter(({ id, label }) => {
        const value = isModernSubtypeId(id) ? id : label;
        return !!alternatives.find((alt) => alt.value === value);
      })
      .map((info) => info.id);
  }, [fieldToConfigure.alternatives, availableSubtypes]);

  const confirmChanges = useCallback(() => {
    updateAlternatives(fieldToConfigure.alternatives ?? []);
    setOpen(false);
  }, [updateAlternatives, fieldToConfigure]);

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      style={{ minWidth: '300px', maxWidth: '300px' }}
    >
      <Dialog.Header>Set available sub type choices</Dialog.Header>
      <Dialog.Body>
        <Box>
          {availableSubtypes.map((info) => (
            <Box flexDirection="row" key={info.id}>
              <Tooltip title={info.problem ?? ''} placement="left">
                <span>
                  <CheckboxWithLabel
                    selected={selectedSubtypes.includes(info.id)}
                    onClick={() => {
                      if (!info.problem) toggleSubtype(info.id, info.label);
                    }}
                    label={info.label}
                    disabled={!!info.problem}
                  />
                </span>
              </Tooltip>
            </Box>
          ))}
        </Box>
      </Dialog.Body>
      <Dialog.Footer>
        <Dialog.CancelButton />
        <Dialog.ConfirmButton label="Confirm" onConfirm={confirmChanges} />
      </Dialog.Footer>
    </Dialog>
  );
}
