import { useContext, useId, useMemo } from 'react';
import { keyBy } from 'lodash';

import { useGetMdfs } from 'api/mdf/useGetMdfs';
import { useGetOrderForms } from 'api/order_forms/useGetOrderForms';
import UserContext from 'contexts/UserContext';
import { getSubMdf, hasPermission, shouldShowField } from 'features/mdf/mdf-utils';
import { useEditorCommandsKeyed } from 'store';
import type { Metadata, NewFieldValue } from 'types/forms/forms';
import type { LayoutSettings, MdfField, MdfPermission, ViewTypes } from 'types/graphqlTypes';
import { FieldTypeEnum } from 'types/graphqlTypes';

import { ErrorField } from './fields/error/ErrorField';
import { FieldMap } from './fields/fields';
import TooltipWrapper from './TooltipWrapper';

import { Wrapper } from './styled';

export interface EditorProps {
  fields: MdfField[];
  defaultLayoutSettings: LayoutSettings[];
  layoutSettings: LayoutSettings[];
  metadata: Metadata;
  permissions: MdfPermission;
  updateFieldValue: (value: NewFieldValue[]) => void;
  style?: Record<string, string>;
  errorMap?: Record<string, string | undefined>;

  view: ViewTypes;

  // Do not apply default value configured in model. Useful for search workflow.
  ignoreDefaultValue?: boolean;

  // Do not apply permissions, useful for search workflow.
  ignorePermissions?: boolean;

  // Force date fields to select a range instead of single date
  forceDateRange?: boolean;

  // Will fire field updates on each change rather than on blur
  fireOnChange?: boolean;

  // Will attempt to autofocus the first field
  autoFocus?: boolean;

  // Indicates there is more vertical space to work with
  moreVerticalSpace?: boolean;
  // If provided, certain workflows are disabled, such as visibility/required.
  // Should only be used in default value editing workflow
  fieldEditMode?: boolean;

  // If provided, all fields are readonly.
  readonly?: boolean;
}

export function MdfEditor({
  fields,
  layoutSettings,
  defaultLayoutSettings,
  view,
  metadata,
  permissions,
  updateFieldValue,
  style,
  fireOnChange,
  errorMap,
  forceDateRange,
  moreVerticalSpace,
  autoFocus,
  fieldEditMode,
  readonly,
  ignorePermissions,
}: Readonly<EditorProps>) {
  const uniqueId = useId();
  const { mdfsSeparated } = useGetMdfs();
  const { groups } = useContext(UserContext);
  const [editorCommands] = useEditorCommandsKeyed();
  const { keyedOrderForms } = useGetOrderForms();

  const subTypes = useMemo(() => {
    return keyBy(mdfsSeparated.subTypes, (mdf) => mdf.label);
  }, [mdfsSeparated.subTypes]);

  const settingsMap = useMemo(() => {
    return keyBy(layoutSettings, (setting) => setting.fieldId);
  }, [layoutSettings]);

  const defaultSettingsMap = useMemo(() => {
    return keyBy(defaultLayoutSettings, (setting) => setting.fieldId);
  }, [defaultLayoutSettings]);

  const visibleFields = useMemo(
    () =>
      fields.filter((f) =>
        shouldShowField(
          f,
          defaultSettingsMap,
          settingsMap,
          fieldEditMode,
          hasPermission(permissions.read[f.fieldId], groups),
        ),
      ),
    [fields, defaultSettingsMap, settingsMap, fieldEditMode, permissions.read, groups],
  );

  if (!visibleFields?.length) {
    return null;
  }

  const onSubtypeFieldUpdate = (
    val: NewFieldValue[],
    mainSubtypeFieldId: string,
    subTypeLabel: string,
  ) => {
    if (!metadata[mainSubtypeFieldId]) {
      // This may happen if a subtype field has a default value.
      // We have to ensure that the underlying sub mdf is chosen to
      // make sure we have the reference to the subtype schema in the metadata payload.
      updateFieldValue([...val, { fieldId: mainSubtypeFieldId, value: subTypeLabel }]);
    } else {
      updateFieldValue([...val]);
    }
  };

  return (
    <Wrapper style={style}>
      {visibleFields.map((field, i) => {
        const Field = FieldMap[field.type];
        const disableEdit = !hasPermission(permissions.write[field.fieldId], groups);
        const subMdf = getSubMdf(field, metadata, subTypes);
        if (!Field) {
          return <ErrorField type={field?.type} key={field.fieldId} />;
        }
        return (
          <span key={`${uniqueId}-${field.fieldId}`}>
            <TooltipWrapper
              readonly={ignorePermissions ? false : readonly}
              disableEdit={ignorePermissions ? false : disableEdit}
              forceAllowPointerEvents={field.type === FieldTypeEnum.relation}
            >
              <Field
                editorId={uniqueId}
                forceDateRange={forceDateRange}
                fireOnChange={fireOnChange}
                autoFocus={autoFocus && i === 0}
                fieldModel={field}
                errorMessage={errorMap ? errorMap[field.fieldId] : undefined}
                fieldSettings={settingsMap[field.fieldId]}
                defaultFieldSettings={defaultSettingsMap[field.fieldId]}
                value={metadata[field.fieldId]}
                setValue={(val) => {
                  if (ignorePermissions || (!readonly && !disableEdit))
                    updateFieldValue([{ value: val, fieldId: field.fieldId }]);
                }}
                moreVerticalSpace={moreVerticalSpace}
                disableEdit={ignorePermissions ? false : readonly || disableEdit}
                view={view}
                commands={editorCommands}
                forms={keyedOrderForms}
              />
            </TooltipWrapper>
            {subMdf && (
              <MdfEditor
                style={{
                  padding: '10px 8px 8px 8px',
                  border: '1px solid rgba(172, 184, 198, 0.25)',
                  borderTop: '0',
                  borderBottomLeftRadius: '4px',
                  marginTop: '-8px',
                  marginBottom: '5px',
                  borderBottomRightRadius: '4px',
                }}
                fields={subMdf.fields}
                defaultLayoutSettings={subMdf.views.default}
                layoutSettings={subMdf.views[view]}
                ignorePermissions={ignorePermissions}
                view={view}
                metadata={metadata}
                permissions={subMdf.permissions}
                updateFieldValue={(val) => onSubtypeFieldUpdate(val, field.fieldId, subMdf.label)}
                errorMap={errorMap}
              />
            )}
          </span>
        );
      })}
    </Wrapper>
  );
}
